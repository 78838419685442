import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { MdDone } from "react-icons/md";
import "./Stepper.css";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import dateformat from "dateformat";
import { useEffect } from "react";
import moment from "moment";
import rejecticon from "../../Images/rejectIcon.svg";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import { IoIosCloseCircle } from "react-icons/io";
import { render } from "react-dom";
import { log } from "@ant-design/icons-react/lib/utils";
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#cde7a8 0%,#cde7a8 50%,#cde7a8 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#cde7a8 0%,#cde7a8 50%,#cde7a8 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: "35px",
    height: "35px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg,#510f30 0%, #510f30 50%, #510f30 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, #510f30  0%, #510f30  50%, #510f30 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: (
      <div className="icon_settings">
        <MdDone />
      </div>
    ),
    2: (
      <div className="icon_settings">
        {props != "Rejected" &&
          <MdDone />
        }
        {props == "Rejected" &&
          <IoIosCloseCircle />
        }
      </div>
    ),
    3: (
      <div className="icon_settings">
        <MdDone />
      </div>
    ),
    // 2: <GroupAddIcon />,
    // 3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({}));

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setsteps] = React.useState([
    { Label: props.t("booked"), Time: "" },
    { Label: props.t("approved"), Time: "" },
    { Label: props.t("ad_posted"), Time: "" },
  ]);

  const [open, setOpen] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState(null);
  const { t } = props;


  function formatDate(date) {
    debugger;
    let dateof = new Date(date)
    const day = dateof.getDate();
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[dateof.getMonth()];
    const year = dateof.getFullYear().toString().slice(-2);
    const hours = dateof.getHours();
    const minutes = dateof.getMinutes();
    console.log("minustes", minutes)
    const zeromin = 0;
    const new_minutes = minutes < 10 ? `${zeromin}${minutes}` : minutes;
    console.log("testing", new_minutes);

    const period = hours < 12 ? 'am' : 'pm';
    const formattedHours = hours % 12 || 12;

    return `${day}-${month}-${year}, ${formattedHours}:${new_minutes} ${period}`;
  }


  useEffect(() => {
    debugger;
    const step = [...steps];
    
    step.forEach((obj) => {
      obj["Time"] = "";
    });
    if (props.businessDays.ad_approve_status) {
      var objectToReplace;
      var newObject;
      switch (props.businessDays.ad_approve_status.toLocaleLowerCase()) {
        case "p":
          setActiveStep(0);
          objectToReplace = step.find(arrayItem => arrayItem.Label === "Rejected" || arrayItem.Label === "مرفوض");
          if (objectToReplace != undefined) {
            newObject = { Label: props.t("Approved"), Time: "" };
            Object.assign(objectToReplace, newObject);
          }

          step.forEach((obj) => {
            if (obj.Label.toLocaleLowerCase() == "booked" || obj.Label == "حجز") {
              // =============== Please Don't remove this code is need to importent =============
              obj.Time = dateformat(
                props.businessDays.created_on,
                "dd-mmm-yy, hh:MM tt"
              );
            }
          });

          break;
        case "a":
          setActiveStep(1);

          objectToReplace = step.find(arrayItem => arrayItem.Label === "Rejected" || arrayItem.Label === "مرفوض");
          if (objectToReplace != undefined) {
            newObject = { Label: props.t("Approved"), Time: "" };
            Object.assign(objectToReplace, newObject);
          }

          step.forEach((obj) => {
            if (obj.Label.toLocaleLowerCase() == "booked" || obj.Label == "حجز") {
              // =============== Please Don't remove this code is need to importent =============
              obj.Time = dateformat(
                props.businessDays.created_on,
                "dd-mmm-yy, hh:MM tt"
              );
              // =============== Please Don't remove this code is need to importent =============

            }
            if (obj.Label.toLocaleLowerCase() == "approved" || obj.Label == "موافقة") {
              // =============== Please Don't remove this code is need to importent =============
              obj.Time = dateformat(
                props.businessDays.ad_approval_time,
                "dd-mmm-yy, hh:MM tt"
              );
            }
            if (props.businessDays.isAddPosted == "true") {
              setActiveStep(2);
              if (obj.Label.toLocaleLowerCase() == "ad posted" || obj.Label == "تم نشر الإعلان") {
                obj.Time = ""; //dateformat(props.businessDays.ad_start_date, "dd") +" - " +dateformat(props.businessDays.ad_end_date, "dd mmm yy");
              }
            }
          });

          break;
        case "r":
          setActiveStep(1);

          objectToReplace = step.find(arrayItem => arrayItem.Label === "Approved" || arrayItem.Label === "موافقة");
          if (objectToReplace != undefined) {
            newObject = { Label: props.t("ad_rejected"), Time: "", Reason: "" };
            Object.assign(objectToReplace, newObject);
          }

          step.forEach((obj) => {
            if (obj.Label.toLocaleLowerCase() == "booked" || obj.Label == "حجز") {
              obj.Time = dateformat(props.businessDays.created_on, "dd-mmm-yy,  hh:MM tt");
            }
            if (obj.Label.toLocaleLowerCase() == "rejected" || obj.Label == "مرفوض") {
              obj.Time = dateformat(props.businessDays.ad_approval_time, "dd-mmm-yy, hh:MM tt");
              obj.Reason = props.businessDays.remarks;
            }

          });
          break;
        default:
          setActiveStep(0);
          step.forEach((obj) => {
            if (obj.Label.toLocaleLowerCase() == "booked" || obj.Label == "حجز") {

              // =============== Please Don't remove this code is need to importent =============

              obj.Time = dateformat(
                props.businessDays.created_on,
                "dd-mmm-yy, hh:MM tt"
              );
            }
          });
          break;
      }
    } else {
      setActiveStep(0);
      step.forEach((obj) => {
        if (obj.Label.toLocaleLowerCase() == "booked") {
          const isoDate = props.businessDays.created_on;
          obj.Time = dateformat(props.businessDays.created_on, "dd-mmm-yy, hh:MM tt"); //formatDate(props.businessDays.created_on); //moment(isoDate, moment.ISO_8601).utcOffset(0).format('DD-MMM-YY, hh:mm a');
          console.log("desogndkf", obj.Time)
        }
      });
    }
    if (JSON.stringify(step) !== JSON.stringify(steps)) {
      setsteps(step);
    }
  }, [props.businessDays, steps]);


  function clickToViewReason(data) {
    setOpen(true);
    setRejectReason(data);
  };
  function handleClose() {
    setOpen(false);
  };

  function rejectIcon() {
    return (
      <div className="rejectIcon">
        <img src={rejecticon} />
      </div>
    )
  }

  return (
    
    <div className="steeper_Container">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label) => (
          <Step key={label.Label} className="booked_text_edit">
            <StepLabel
              StepIconComponent={label.Label != "Rejected" && label.Label != "مرفوض" ? ColorlibStepIcon : rejectIcon}
              style={{ fontSize: "16px" }}
            >
              {label.Label}
            </StepLabel>

            <div>
            <div className="stepper_div_content"> {label.Time} </div>
              {/* <div className="stepper_div_content" style={{ borderLeft: "1px solid #bdbdbd" }}> {label.Time} </div> */}
              {(label.Label == "Rejected") &&
                <div className="stepper_div_reject_reason" onClick={() => clickToViewReason(label.Reason)}> Click to see reason</div>
              }
              {( label.Label == "مرفوض") &&
                <div className="stepper_div_reject_reason" onClick={() => clickToViewReason(label.Reason)}> انقر لمعرفة السبب</div>
              }
            </div>
          </Step>
        ))}
      </Stepper>
      { }
      <Modalcomp
        xswidth={"md"}
        clrchange={`${props.i18n.language == 'ar' ? "model-head" : ""} textclr`}
        title={t("ad_rejected_reason")}
        visible={open}
        closemodal={handleClose}
        custommodalsize='upload_instruction_adv'
        modelwidthClass="upload_ins_popup"
        t={t}
        i18n={props.i18n}
      >
        <div className={`${localStorage.nurse_language=='ar'?"ar_reject_reason_content":""} reject_reason_content`}>{rejectReason}</div>
        <p style={{ fontSize: "16px" }} className={`${localStorage.nurse_language=='ar'?"ar_reject_reason_content":""}`}>Please check your mail.</p>
      </Modalcomp>
    </div>
  );

}

