import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Tabs, message, Select } from "antd";
import Checkbox from "@material-ui/core/Checkbox";
import "./BookingDetails.css";
import UploadMedia from "./UploadInstruction";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import Calendar from "./Calendar";
import { apiurl, kneturl, paymentsuccessurl } from "../../App";
import Axios from "axios";
import { FiInfo } from "react-icons/fi";
import dateformat from "dateformat";
import "antd/dist/antd.css";
import moment from "moment";
import Full from "../../Images/vendor-List-Page-Media.png";
import CloseIcon from "@material-ui/icons/Close";
import ShowNotification from "../ShowNotification/ShowNotification";
import VideoPlayerPopup from "./VideoPlayerPopup";
import { withRouter } from "react-router";
import mastercard from "../../Images/masterCardImage.png";
import knetImage from "../../Images/knetImage.png";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import { formatMoney } from "../../App";

const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

const key = "updatable";

let startdate = "";
let enddate = "";
class AdBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect_Data: false,
      open: false,
      edit: false,
      loading: false,
      imageChanged: false,
      data: [],
      placementLocation: [],
      sizeData: [],
      sizeCheck: "S",
      adfeeperday: "",
      adtotalcost: "",
      location: "",
      imageUrl: "",
      imageName: "",
      imagedata: [],
      adsize: "",
      ad_details: [],
      activeKey: "1",
      advertisement_name: "",
      startdate: startdate,
      endDate: enddate,
      checked: false,
      clickControl: false,
      minDate: moment(startdate),
      noOfAvailableDays: 0,
      startdateError: "",
      enddateError: "",
      sizeError: "",
      locationError: "",
      imageError: "",
      feeError: "",
      totalcostError: "",
      advertisement_name_Error: "",
      dateError: false,
      hidefilelist: false,
      totalDays: null,
      filename: "",
      thumbnail_imageurl: "",
      excludedDays: [],
      isshowexclude: false,
      visible: false,
      successmsg: "",
      issuccess: false,
      videoPlayerPopupOpen: false,
      invaliddate: false,
      path_type: "",
      paymentTypeModel: false,
      upload_file_size: "",
      imageUrldb: "",
    };
  }

  callback = (key) => {
    if (key == 2 && this.state.edit) {
      this.resetFormValue();
    }

    this.setState({
      activeKey: key,
    });
  };

  getRangeData = (data) => {
    this.setState({ isshowexclude: false, invaliddate: false });
    if (data.enddate === null) {
      this.setState({ startdate: data.startdate, startdateError: "", endDate: "" }, () =>
        this.compareDate()
      );
    } else {
      let TotalslotsAvailable = data.TotalslotsAvailable.filter((object) => {
        return object.availableSlots == 0;
      });
      let excludedDays = [];

      if (data.startdate < data.enddate) {
        TotalslotsAvailable.map((obj) => {
          if (
            moment(obj["selected_date"]).isBetween(
              moment(data.startdate),
              moment(data.enddate)
            )
          ) {
            excludedDays.push({
              display_date: moment(obj["selected_date"], "YYYY-MM-DD").format(
                "DD-MMM-YY"
              ),
              date: obj["selected_date"],
            });
          }
        });
        this.setState(
          {
            startdate: data.startdate, endDate: data.enddate, excludedDays,
            startdateError: "", enddateError: "",
          }, () => this.compareDate());
        console.log("thick", this.state.excludedDays);
      } else {
        TotalslotsAvailable.map((obj) => {
          if (
            moment(obj["selected_date"]).isBetween(
              moment(data.enddate),
              moment(data.startdate)
            )
          ) {
            excludedDays.push({
              display_date: moment(obj["selected_date"], "YYYY-MM-DD").format(
                "DD-MMM-YY"
              ),
              date: obj["selected_date"],
            });
          }
        });
        this.setState(
          {
            startdate: data.enddate, endDate: data.startdate, excludedDays,
            startdateError: "", enddateError: "",
          },
          () => this.compareDate()
        );
      }
    }
  };

  calcAvailableDays = (excludedDays) => {
    let arr = [];
    excludedDays.forEach((obj) => {
      arr.push(obj["date"]);
    });
    this.setState(prevState => ({
      noOfAvailableDays: prevState.totalDays - arr.length,
      adtotalcost: (prevState.totalDays - arr.length) * prevState.adfeeperday
    }));

  };

  handleChange = (info) => {
    if (info.fileList.length == 0) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.setState({ fileList, imagedata: fileList, hidefilelist: false });
    }

    if (info.fileList.length > 0) {
      if (
        info.fileList[0].type == "image/png" ||
        info.fileList[0].type == "video/mp4"
      ) {
        let fileList = [...info.fileList];

        fileList = fileList.slice(-1);

        fileList = fileList.map((file) => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });

        this.setState({
          fileList,
          imagedata: fileList,
          imageChanged: true,
          hidefilelist: false,
          imageError: false,
        });
      } else {
        this.setState({ open: true });
      }
    }
  };

  changeTabFun = (data) => {
    if (
      new Date(data.ad_start_date) < new Date() &&
      new Date(data.ad_end_date) < new Date() &&
      !data.isrepeat
    ) {
      this.handleopenSuccess("Advertisement expired", false);
    } else if (
      new Date(data.ad_start_date) < new Date() &&
      new Date(data.ad_end_date) > new Date() &&
      !data.isrepeat
    ) {
      this.handleopenSuccess("Advertisement already posted", false);
    } else {
      this.setState(
        {
          activeKey: "1",
          editData: data,
          imageChanged: false,
          edit: data.isrepeat ? false : true,
        },
        () => this.storeadSize(this.state.editData.ad_size)
      );

      this.setState({
        advertisement_name: data.ad_name,
        id: data.id,
        startdate: data.isrepeat
          ? dateformat(new Date(Date.now() + 3600 * 1000 * 48), "yyyy-mm-dd")
          : dateformat(data.ad_start_date, "yyyy-mm-dd"),
        endDate: data.isrepeat
          ? dateformat(new Date(Date.now() + 3600 * 1000 * 48), "yyyy-mm-dd")
          : dateformat(data.ad_end_date, "yyyy-mm-dd"),
        adtotaldays: data.ad_total_days,
        adsize: data.ad_size,
        location: data.ad_location_id,
        adfeeperday: data.ad_fee_per_day,
        adtotalcost: data.ad_total_cost,
        thumbnail_imageurl: data.ad_filename,
        imagedata: data.ad_filename,
        filename: data.ad_filename,
        excludedDays: data.excluded_dates
      });
    }
  };

  changeSizeCheck = (data) => {
    this.setState({ sizeCheck: data });
  };

  componentWillMount() {
    this.getfilesize()
    if (Object.getOwnPropertyNames(this.props.edit_booking_data).length > 0) {
      this.changeTabFun(this.props.edit_booking_data);
    }

    if (localStorage.getItem("paymentstatus") == "cancel") {

      if (localStorage.getItem("nurse_language") == "ar"){
        this.handleopenSuccess("تم إلغاء الدفع", false);
      }else {
        this.handleopenSuccess("Payment cancelled", false);
      }

      

    } else if (localStorage.getItem("paymentstatus") == "completed") {
      let payloaddata = JSON.parse(localStorage.getItem("payloaddata"));
      payloaddata["paymentId"] = localStorage.getItem("resultindicator");
      payloaddata["sessionid"] = localStorage.getItem("session");
      payloaddata["referrence"] = localStorage.getItem("referenceid");
      payloaddata["orderId"] = localStorage.getItem("order_on");
      payloaddata["paymentGatewayName"] = localStorage.getItem("paymentGatewayName");
      payloaddata["module_name"] = "Nurse";
      this.insertAdBooking(payloaddata);
    }

    localStorage.setItem("paymentstatus", "");
    localStorage.setItem("resultindicator", "");
    localStorage.setItem("session", "");
    localStorage.setItem("order_on", "");
    localStorage.setItem("paymentGatewayName", "")
    this.handlePlacement();
  }

  getfilesize = () => {
    let obj = {
      contenttype: "Advertisement",
      module_id: '5'
    };
    Axios({
      method: "POST",
      url: apiurl + "admin/getFileSizeByModule",
      data: obj,
    }).then((res) => {
      if (res.data.status == 1) {
        this.setState(
          {
            upload_file_size: res.data.data[0]
          })
      }
    })
  }

  generateThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.load();
      video.muted = true;
      video.onloadeddata = () => {
        video.currentTime = 0.1;
      };
      video.onseeked = () => {
        this.captureFrame(video, file);
        resolve();
      };
      video.onerror = (error) => {
        this.setState({ imageUrlThumb: file });
        reject(error);
      };
    });
  };

  captureFrame = (video, file) => {
    let canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    let dataURI = canvas.toDataURL('image/jpeg');
    let obj = {
      canvas: canvas,
      file: this.dataURLtoFile(dataURI, `${+new Date()}.jpg`),
      base64: dataURI
    };
    this.displayBase64Image(obj.base64, file)
  };

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  displayBase64Image(base64String, file) {
    let base64Data = base64String.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blobs = new Blob([byteArray], { type: 'image/jpeg' });

    const formData = new FormData();
    formData.append('module_id', "5");
    formData.append('file', blobs);
    formData.append('pathLocation', "ADV/")

    Axios({
      method: "POST",
      url: apiurl + "admin/awsS3FileUpload",
      data: formData,
    }).then(async (response) => {
      let obj = response.data.filepath
      let cleanedObj = await this.removeDuplicateKeys(obj);
      this.setState({ imageUrlThumb: cleanedObj.privatepathurl });
    })
  }

  uploadFile = (e) => {
    const file = e.target.files[0];
    let maxSizeBytes = Number(this.state.upload_file_size.size_in_byts);
    let isValid = this.validation();
    this.setState({ setlet_Progressing: 0 })
    if (isValid === true && e.target.files.length != 0) {
      if (e.target.files[0].size <= maxSizeBytes) {
        if (((
          e.target.files[0].type == "image/png" ||
          e.target.files[0].type == "image/jpeg" ||
          e.target.files[0].type == "image/jpg" ||
          e.target.files[0].type == "video/mp4") && e.target.files[0].size <= maxSizeBytes)
        ) {

          this.setState(
            {
              filename: e.target.files[0].name,
              path_type: e.target.files[0].type.split('/')[0],
            }
          );
          const formData = new FormData();
          formData.append('module_id', "5");
          formData.append('file', e.target.files[0]);
          formData.append('pathLocation', "ADV/")

          Axios({
            method: "POST",
            url: apiurl + "admin/awsS3FileUpload",
            data: formData,
            onUploadProgress: (progressEvent) => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.setState({ setlet_Progressing: percentage })
            },
          }).then(async (response) => {
            if ((this.state.path_type).toLowerCase() == "video" && file) {
              try {
                await this.generateThumbnail(file);
              } catch (error) {
              }
            }
            let obj = response.data.filepath
            let cleanedObj = await this.removeDuplicateKeys(obj);
            this.setState(
              {
                imageUrl: cleanedObj.location,
                imageUrldb: cleanedObj.privatepathurl,
                imageChanged: true,
              }
            );
            if ((this.state.path_type).toLowerCase() == "image") {
              this.setState({ imageUrlThumb: cleanedObj.privatepathurl });
            }
          });
        } else {
          this.setState({ open: true });
        }
      } else {
        this.setState({ open: true });
      }
    }
  };

  removeDuplicateKeys = (obj) => {
    let newObj = {};
    let lowerCaseKeys = {};
    for (let key in obj) {
      let lowerKey = key.toLowerCase();
      lowerCaseKeys[lowerKey] = key;
    }
    for (let key in obj) {
      let lowerKey = key.toLowerCase();
      newObj[lowerKey] = obj[lowerCaseKeys[lowerKey]];
    }
    return newObj;
  }

  handleChangeSize = () => {
    Axios({
      method: "GET",
      url: apiurl + "Diet/get_mas_size_master",
    }).then((response) => {
      let data = response.data;
      if (data.status == 1) {
        let arr_obj = data.data.filter((obj) => {
          return obj.size.toLowerCase() == "full";
        });

        if (arr_obj.length > 0 && !this.state.edit) {
          this.setState({ adsize: arr_obj[0]["id"] }, () => {
            this.storeadSize(arr_obj[0]["id"]);
          });
        }
        this.setState({ sizeData: data.data });
        this.setState({ sizeData: data.data });
      }
    });
  };

  handlePlacement = () => {
    Axios({
      method: "GET",
      url: apiurl + "Diet/get_mas_placement_location",
    })
      .then((response) => {
        let data = response.data;
        if (data.status == 1) {
          if (data.data.length > 0) {
            this.setState(
              { placementLocation: data.data, location: data.data[0].id },
              () => {
                this.handleChangeSize();
                this.refs.calendar.locationChange(true);
              }
            );
          }
        }
      });
  };

  validation = () => {
    let startdateError = "";
    let enddateError = "";
    let sizeError = "";
    let locationError = "";
    let feeError = "";
    let totalcostError = "";
    let imageError = "";
    let advertisement_name_Error = "";

    const { t } = this.props
    const fieldRequired = t("fieldRequired");
    if (this.state.advertisement_name === "") {
      advertisement_name_Error = fieldRequired;
    }

    if (this.state.startdate === "") {
      startdateError = fieldRequired;
    }
    if (this.state.endDate === "") {
      enddateError = fieldRequired;
    }

    if (this.state.adsize === "") {
      sizeError = fieldRequired;
    }

    if (this.state.location === "") {
      locationError = fieldRequired;
    }

    if (this.state.adfeeperday === "") {
      feeError = fieldRequired;
    }

    if (this.state.adtotalcost === "") {
      totalcostError = fieldRequired;
    }

    if (this.state.imageUrl == null) {
      imageError = fieldRequired;
    }

    if (
      startdateError ||
      enddateError ||
      sizeError ||
      locationError ||
      feeError ||
      totalcostError ||
      imageError || advertisement_name_Error
    ) {
      this.setState({
        startdateError,
        enddateError,
        sizeError,
        locationError,
        feeError,
        totalcostError,
        imageError,
        advertisement_name_Error,
      });
      return false;
    }
    return true;
  };

  handleDrop = (data) => {
    let startDate = moment();
    let startdate = moment(startDate).add(2, "days").format("YYYY-MM-DD");
    let enddate = moment(startdate);

    if (data === 1) {
      this.setState(
        {
          location: 1,
          startdate: startdate,
          endDate: enddate,
        },
        () => {
          this.refs.calendar.locationChange(true);
          this.getRate(data, this.state.adsize);
        }
      );
    }

    if (data === 2) {
      this.setState(
        {
          location: 2,
          startdate: startdate,
          endDate: enddate,
        },
        () => {
          this.refs.calendar.locationChange(true);
          this.getRate(data, this.state.adsize);
        }
      );
    }
  };

  getRate = (data, size) => {
    console.log("Rate data", size)
    let ratedata = {
      vendor_type_id: 5,
      placement_location_id: data,
      size_id: size,
    };

    Axios({
      method: "POST",
      url: apiurl + "Diet/get_ad_rate_vendor",
      data: ratedata,
    })
      .then((response) => {
        this.setState({ adfeeperday: response.data.data[0].rate }, () =>
          this.checkHours()
        );
      })
      .catch((err) => { });
  };

  handleSubmit = () => {
    // this.setState({ paymentTypeModel: true });
    if (this.state.advertisement_name == "") {
      const { t } = this.props;
      const fieldRequired = t('Field required')
      this.state.advertisement_name_Error = fieldRequired;
    }
    else {
      this.setState({ paymentTypeModel: true });
    }
  };

  resetFormValue = () => {
    this.setState({
      advertisement_name: "",
      adfeeperday: "",
      location: "",
      adtotalcost: "",
      imageName: "",
      adsize: "",
      startdate: this.state.minDate,
      endDate: this.state.minDate,
      feeError: false,
      totalcostError: false,
      filename: "",
      edit: false,
      thumbnail_imageurl: "",
      excludedDays: []
    }, () => {
      this.handlePlacement();
      this.handleChangeSize();
    });
  };


  insertAdBooking = (details) => {
    ;
    this.setState({ clickControl: true });
    Axios({
      method: "POST",
      url: apiurl + "Diet/insertAdBooking",
      data: details,
    })
      .then((response) => {
        if (response.data.data[0].p_return_value != -1) {
          this.setState({ invaliddate: true })
          const { t } = this.props;
          const advertisementAddedSuccessfully = t("advertisementAddedSuccessfully")
          this.handleopenSuccess(advertisementAddedSuccessfully, true);

        } else if (response.data.data[0].p_return_value == -1) {
          this.handleopenSuccess("Advertisement Already Booked for Selected Date", false);
        } else {
          this.handleopenSuccess("Advertisement Booked Failed", false);
        }
        ;
        this.setState({ clickControl: false });
        this.resetFormValue();
        this.setState({ hidefilelist: true, recallget: true });
      })
      .catch((error) => {
      });
  };

  getInitiatePayment = (details) => {
    let obj = {
      vendor_name: JSON.parse(localStorage.getItem("login_data")).user_name,
      phone: JSON.parse(localStorage.getItem("login_data")).phone,
      customer_email: JSON.parse(localStorage.getItem("login_data")).email,
      amount: details,
      OrderId: localStorage.getItem("order_on"),
    };

    Axios({
      method: "POST",
      url: apiurl + "admin/createSessionId",
      data: obj,
    }).then((res) => {
      window.Checkout.configure({
        session: {
          id: res.data.data.session.id
        }
      });
      window.Checkout.showPaymentPage()
    }).catch((error) => {
      console.log("error");
    })
  }
  generatePaymentOrderNo() {
    let first_segment = "tom";
    let second_segment = (Math.random() + 1).toString(36).substring(2);
    return first_segment + second_segment;
  }
  // Edit Api
  editAdBooking = (details) => {

    this.setState({ clickControl: true });
    const isValid = this.validation();
    let arr = [];
    this.state.excludedDays.map((obj) => {
      arr.push(obj["date"]);
    });
    let payload = {
      ad_id: this.state.editData.id,
      imageArray: this.state.imageUrl,
      startdate: dateformat(this.state.startdate, "yyyy-mm-dd"),
      enddate: dateformat(this.state.endDate, "yyyy-mm-dd"),
    }

    Axios({
      method: "POST",
      url: apiurl + "Diet/editAdBooking ",
      data: payload,
    })
      .then((response) => {
        if (response.data.status == 2) {
          this.handleopenSuccess(
            "Advertisement Already Booked for Selected Date",
            false
          );
        } else if (response.data.status == 1) {
          this.setState({ invaliddate: true, pathname: "", setlet_Progressing: 0 });
          this.handleopenSuccess("Advertisement updated successfully!", true);
        } else {
          this.handleopenSuccess("Advertisement updated failed", false);
        }
        ;
        this.setState({ clickControl: false });

        this.resetFormValue();
        this.setState({ hidefilelist: true, recallget: true });
      })
      .catch((error) => {
      });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  changeData = (e, key) => {
    if (key === "fee") {
      this.setState(
        {
          adfeeperday: e.target.value,
        },
        () => this.setState({ feeError: false })
      );
    }
    if (key === "total") {
      this.setState(
        {
          adtotalcost: e.target.value,
        },
        () => this.setState({ totalcostError: false })
      );
    }

    if (key === "location") {
      if (e === "Category") {
        this.setState({
          location: 2,
        });
      }

      if (e === "AppHome") {
        this.setState({
          location: 1,
        });
      }
    }
  };

  checkHours = () => {
    let startDate = moment(this.state.endDate).format("DD");
    let endDate = moment(this.state.startdate).format("DD");
    let fromMonth = moment(this.state.startdate).format("MM");
    let toMonth = moment(this.state.endDate).format("MM");
    let current_year = moment().year();
    let to_year = moment(this.state.endDate).year();

    if (parseInt(fromMonth) < parseInt(toMonth)) {
      var monthDiff =
        moment(this.state.endDate).format("MM") -
        moment(this.state.startdate).format("MM");
    } else if (parseInt(toMonth) < parseInt(fromMonth)) {
      let monthabs =
        moment(this.state.startdate).format("MM") -
        moment(this.state.toDate).format("MM") -
        12 * (to_year - current_year);
      var monthDiff = Math.abs(monthabs);
    } else if (parseInt(fromMonth) == parseInt(toMonth)) {
      var monthDiff = 0;
    }
    let daysInMonth = 0;
    for (let i = 0; i < monthDiff; i++) {
      let filteredMonth = parseInt(fromMonth) + parseInt(i);
      daysInMonth += new Date(current_year, filteredMonth, 0).getDate();
    }

    let startDateMoment = moment(this.state.startdate);
    let endDateMoment = moment(this.state.endDate);

    if (endDateMoment.isBefore(startDateMoment)) {
      let daysUntilEndOfYear = moment(this.state.startdate).endOf('year').diff(startDateMoment, 'days') + 1;
      let daysFromStartOfNextYear = endDateMoment.dayOfYear();

      let totalDays = daysUntilEndOfYear + daysFromStartOfNextYear;

      this.setState({ totalDays }, () =>
        this.calcAvailableDays(this.state.excludedDays)
      );
    } else {
      let totalDays = endDateMoment.diff(startDateMoment, 'days') + 1;

      this.setState({ totalDays }, () =>
        this.calcAvailableDays(this.state.excludedDays)
      );
    }
  };

  datepickerChange = (data, key) => {
    if (key === "startdate") {
      this.setState(
        {
          startdate: data,
        },
        () => this.compareDate()
      );
    }
    if (key === "enddate") {
      this.setState(
        {
          endDate: data,
        },
        () => this.compareDate()
      );
    }
  };

  compareDate = () => {
    const startDate = new Date(this.state.startdate);
    const endDate = new Date(this.state.endDate);
    let language = localStorage.getItem("nurse_language");
    if (startDate <= endDate) {
      this.setState({ dateError: false });
      this.checkHours();
      if (startDate.getDate() == endDate.getDate()) {
        // Check if the elements exist before applying the styles
        const firSel = document.getElementsByClassName("table_fir_sel")[0];
        const secSel = document.getElementsByClassName("table_sec_sel")[0];

        if (firSel) {
          firSel.style.setProperty("border-radius", "25px", "important");
        }
        if (secSel) {
          secSel.style.setProperty("border-radius", "25px", "important");
        }

      } else {
        const firSel = document.getElementsByClassName("table_fir_sel")[0];
        const secSel = document.getElementsByClassName("table_sec_sel")[0];
        const midSel = document.getElementsByClassName("table_inter_sel");
        const booksecSel = document.getElementsByClassName("prev_slotful_table_sec_sel")[0];

        midSel.forEach((element) => {
          element.style.setProperty("border-radius", "0px", "important");
        })
        // Ensure elements exist before applying styles
        if (language === "ar"){
          if (firSel) {
            firSel.style.setProperty("border-radius", "0px", "important");
            firSel.style.setProperty("border-top-right-radius", "25px", "important");
            firSel.style.setProperty("border-bottom-right-radius", "25px", "important");
        }
        
        if (secSel) {
            secSel.style.setProperty("border-radius", "0px", "important");
            secSel.style.setProperty("border-top-left-radius", "25px", "important");
            secSel.style.setProperty("border-bottom-left-radius", "25px", "important");
        }
        if (booksecSel){
          booksecSel.style.setProperty("border-radius", "0px", "important");
          booksecSel.style.setProperty("border-top-left-radius", "25px", "important");
          booksecSel.style.setProperty("border-bottom-left-radius", "25px", "important");
        }
        }else{
          if (firSel) {
              firSel.style.setProperty("border-radius", "0px", "important");
              firSel.style.setProperty("border-top-left-radius", "25px", "important");
              firSel.style.setProperty("border-bottom-left-radius", "25px", "important");
          }
          
          if (secSel) {
              secSel.style.setProperty("border-radius", "0px", "important");
              secSel.style.setProperty("border-top-right-radius", "25px", "important");
              secSel.style.setProperty("border-bottom-right-radius", "25px", "important");
          }
          if (booksecSel){
            booksecSel.style.setProperty("border-radius", "0px", "important");
            booksecSel.style.setProperty("border-top-right-radius", "25px", "important");
            booksecSel.style.setProperty("border-bottom-right-radius", "25px", "important");
          }
        }
      }
    } else {
      const firSel = document.getElementsByClassName("table_fir_sel")[0];
      const secSel = document.getElementsByClassName("table_sec_sel")[0];
      const booksecSel = document.getElementsByClassName("prev_slotful_table_sec_sel")[0];

      if (firSel) {
        firSel.style.removeProperty("border-radius");
      }
      if (secSel) {
        secSel.style.removeProperty("border-radius");
      }
      if (booksecSel) {
        booksecSel.style.removeProperty("border-radius");
      }
      this.setState({ dateError: true });
    }
  };

  placementLocation = () => {
    let locations = [];
    for (let i = 0; i < this.state.placementLocation.length; i++) {
      locations.push(
        <Option key={i + 1} value={this.state.placementLocation[i].id}>
          {this.state.placementLocation[i].ad_location}
        </Option>
      );
    }
    return locations;
  };

  storeadSize = (data) => {
    this.setState(
      { adsize: data },
      () => this.getRate(this.state.location, data),
      this.setState({ sizeError: false })
    );
  };

  uploadthumbnailFile = (e) => {
    let formData = new FormData();
    formData.append("imageArray", e.target.files[0]);
    Axios({
      method: "POST",
      url: apiurl + "Diet/uploadThumbnailImage",
      data: formData,
    })
      .then((response) => {
        if (response.status == "200") {
          if (response.data.file.path.split("public/").length == 2) {
            let api_path = apiurl + response.data.file.path.split("public/")[1];

            this.setState({
              thumbnail_imageurl: api_path,
            });
          }
        }
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  };

  changeAdName(e) {
    let isnum = /^\d+$/.test(e.key);
    if (isnum) {
      e.preventDefault();
    }
  }

  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false, imageChanged: false })
  };

  viewProfile = function () {
    const { history } = this.props;
    history.push("/Home/advertisement");
    window.location.reload();
  };
  handleOpenVideoPlayerPopup = (isOpen) => {
    this.setState({ videoPlayerPopupOpen: isOpen });
  };


  handleClosePaymentTypeModel = () => {
    this.setState({ paymentTypeModel: false });
  };

  selectPaymentType = (cardType) => {
    localStorage.setItem("cardType", cardType);
    const isValid = this.validation();
    let arr = [];
    this.state.excludedDays.map((obj) => {
      arr.push(obj["date"]);
    });
    let order_on = this.generatePaymentOrderNo();

    const payload = {
      adtitle: "Nurse",
      startdate: dateformat(this.state.startdate, "yyyy-mm-dd"),
      enddate: dateformat(this.state.endDate, "yyyy-mm-dd"),
      adtotaldays: this.state.totalDays - arr.length,
      adsize: this.state.adsize,
      adlocationId: this.state.location,
      adfeeperday: this.state.adfeeperday,
      adtotalcost: this.state.adtotalcost,
      advendorId: localStorage.getItem("nurse_vendor_id")
        ? localStorage.getItem("nurse_vendor_id")
        : "11",
      activeflag: 1,
      createdby: 1,
      modifiedby: 1,
      ipaddress: "126.183.0.1",
      path_name: "",
      filepath: this.state.imageUrl.split("?")[0],
      filename: this.state.thumbnail_imageurl,
      advertisement_name: this.state.advertisement_name,
      excludedDays: arr.length == 0 ? "" : arr.toString(),
      paymentStatus: 1,
      paymentId: localStorage.getItem("resultindicator"),
      OrderId: order_on,
      created_on:
        new Date().getFullYear() +
        "-" +
        (new Date().getUTCMonth() + 1) +
        "-" +
        new Date().getDate() +
        " " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes() +
        ":" +
        new Date().getSeconds(),
      type: this.state.path_type.toLowerCase()
    }

    localStorage.setItem("order_on", order_on)

    if (cardType == "mastercard") {
      if (this.state.edit === false && !this.state.dateError) {
        let payloadstr = JSON.stringify(payload);
        localStorage.setItem("payloaddata", payloadstr);
        this.getInitiatePayment(this.state.adtotalcost);
        this.setState({ paymentTypeModel: false });
      }
      if (this.state.edit === true && isValid && !this.state.dateError) {
        this.editAdBooking(payload);
        this.setState({ paymentTypeModel: false });
      }
    } else if (cardType == "knet") {

      if (this.state.edit === true && isValid && !this.state.dateError) {
        this.editAdBooking(payload);
        this.setState({ paymentTypeModel: false });
      }
      else {
        let payloadstr = JSON.stringify(payload);
        localStorage.setItem("payloaddata", payloadstr);

        let url = kneturl +
          "price=" +
          this.state.adtotalcost +
          "&name=" +
          JSON.parse(localStorage.getItem("login_data")).user_name +
          "&email=" +
          JSON.parse(localStorage.getItem("login_data")).email +
          "&phone=" +
          JSON.parse(localStorage.getItem("login_data")).phone +
          "&return_url=" +
          paymentsuccessurl;
        window.open(url, '_self');
      }
    }
  };

  render() {
    const { TabPane } = Tabs;
    const { t } = this.props;
    const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.handleChange,
      multiple: true,
    };

    return (
      <div className="booking_createlist booking_createlist--advertisement booking_ad">
        <Grid container className="calendar_container" spacing={3}>
          <Grid
            item
            xs={12}
            md={7}
            style={{
              display: this.state.imageChanged == "" ? "block" : "none", paddingRight: '0px',
              order: (this.props.i18n.language == 'ar' ? 1 : 0)
            }}
          >
            <Calendar
              ref={"calendar"}
              getDate={(data) => this.getRangeData(data)}
              aftertwodays={true}
              userId={this.props.userId}
              clinicId={this.props.clinicId != undefined && this.props.clinicId}
              showSlots={true}
              {...this.state}
              t={t}
              i18n={this.props.i18n}
              statetrue={this.state.edit}
            />
            <div>
              <p className="ad-note-text">
                {t('ad_cal_note')}
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            style={{
              display: this.state.imageChanged == "" ? "none" : "block",
              paddingRight: '0px', order: (this.props.i18n.language == 'ar' ? 1 : 0)
            }}
          >
            <div>
              {this.state.edit === true ?
                <div className="datebook_container">
                  <Button className="datebook_button"
                    onClick={!this.state.clickControl ? () => { this.editAdBooking() } : null}
                    style={{ textTransform: "none" }}>
                    {" "}
                    {t("update")}{" "}
                  </Button>
                </div> :
                <div className="datebook_container">
                  <Button className="datebook_button"
                    onClick={!this.state.clickControl ? this.handleSubmit : null}
                    style={{ textTransform: "none" }}>
                    {" "}
                    {t("proceed_to_book")}{" "}
                  </Button>
                </div>
              }

              {this.state.path_type.toLowerCase() == "video" &&
                <>
                  <img className={this.state.adsize == 1 ? "mobile_icon_video" : "mobile_icon_videofull"} src={Full}>
                  </img>
                  <video className={this.state.adsize == 1 ? "inner_img_half_video" : "inner_img_full_video"}
                    src={this.state.imageUrl} type="video/mp4" controls />
                </>
              }
              {this.state.path_type.toLowerCase() != "video" &&
                <>
                  <img className="mobile_icon" src={Full}>
                  </img><img style={{ left: (this.props.i18n.language == 'ar' ? "31%" : "") }} className={this.state.adsize == 1 ? "inner_img_half" : "inner_img_full"}
                    src={this.state.imageUrl}></img>
                </>
              }
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            className="alignad_box"
            style={{ paddingLeft: this.props.i18n.language == 'ar' ? '12px' : '0px', paddingRight: (this.props.i18n.language == 'ar' ? '0px' : '12px') }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                className="create_container"
                style={{ display: "flex", paddingBottom: '15px' }}
              >
                <div style={{ width: "37%", paddingRight: (this.props.i18n.language == 'ar' ? '0' : '3%'), paddingLeft: (this.props.i18n.language == 'ar' ? '3%' : '0') }} className={`${this.props.i18n.language == 'ar' ? "order_2" : ""}`}>
                  <label className="labeltxt"> {t('tbl_head_from')} </label>
                  <input
                    type="text"
                    className="html__input"
                    value={this.state.startdate != "" && this.state.invaliddate == false ? moment(this.state.startdate).format("DD-MMM-YY") : ""}
                  ></input>
                  <div className="validation__error" style={{ margin: "0px" }}>
                    {this.state.startdateError && this.state.startdateError}
                  </div>
                </div>
                <div style={{ width: "31.5%" }} className={`${this.props.i18n.language == 'ar' ? "order_1" : ""}`}>
                  <label className="labeltxt"> {t('tbl_head_to')} </label>
                  <input
                    type="text"
                    className="html__input"
                    value={this.state.endDate != "" && this.state.invaliddate == false ? moment(this.state.endDate).format("DD-MMM-YY") : ""}
                  ></input>

                  <div
                    className="validation__error--minus errmsg"
                    style={{ margin: "0px" }}
                  >
                    {this.state.dateError &&
                      t('to_date_should_be_greater_than_from_date')}
                  </div>

                  <div className="validation__error" style={{ margin: "0px" }}>
                    {this.state.enddateError && this.state.enddateError}
                  </div>
                </div>

                <div
                  style={{ width: "31.5%", paddingLeft: (this.props.i18n.language == 'ar' ? '0' : '3%'), paddingRight: (this.props.i18n.language == 'ar' ? '3%' : '0') }}
                  className={`${this.props.i18n.language == 'ar' ? "order_0" : ""}`}
                >
                  <div className="formdiv">
                    <label className="labeltxt">{t('excluded_days')}</label>
                    {this.state.excludedDays.length != 0 && (
                      <div
                        style={{
                          display: "flex",
                          position: 'relative',
                          top: '5px',
                          fontSize: localStorage.nurse_language == 'ar' ? "11px" : "13px",
                          color: "#707070",
                          direction: this.props.i18n.language == "ar" ? "rtl" : ""
                        }}
                      >
                        {" "}
                        <span style={{ direction: this.props.i18n.language == "ar" ? "ltr" : "" }}>{this.state.excludedDays[0]["display_date"]}</span>
                        <span>{this.state.excludedDays.length > 1 ? ", ..." : ""}{" "}</span>
                        <span
                          style={{
                            display:
                              this.state.excludedDays.length > 1
                                ? "block"
                                : "none",
                            fontSize: localStorage.nurse_language == 'ar' ? "9px" : "12px",
                            paddingTop: "4px",
                            paddingLeft: localStorage.nurse_language == 'ar' ? "0px" : "3px",
                            marginTop: "-2px"
                          }}
                          className="readmore_cls"
                          onClick={() => {
                            this.setState({ isshowexclude: true });
                          }}
                        >
                          {" "}
                          {t('view_more')}{" "}
                        </span>{" "}
                        {this.state.isshowexclude && (
                          <div className={`${localStorage.nurse_language == 'ar' ? "ar_excludemorediv" : ""} excludemorediv`}>
                            <div style={{ textAlign: localStorage.nurse_language == 'ar' ? "left" : "right" }}>
                              <CloseIcon
                                className="excludemorediv_close"
                                onClick={() => {
                                  this.setState({ isshowexclude: false });
                                }}
                              ></CloseIcon>
                            </div>
                            <div className={`${localStorage.nurse_language == 'ar' ? "ar_textalign" : ""} excludemoredatemaindiv`}>
                              <div style={{ display: "table" }} >
                                {this.state.excludedDays.map((obj) => (
                                  <div
                                    key={obj["display_date"]}
                                    className="excludemoredatediv"
                                  >
                                    {obj["display_date"]}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className="create_container"
                style={{ display: "flex", paddingBottom: '15px' }}
              >
                <div style={{
                  width: "37%", paddingRight: (this.props.i18n.language == 'ar' ? '0' : '3%'),
                  paddingLeft: (this.props.i18n.language == 'ar' ? '3%' : '0')
                }} className={`${this.props.i18n.language == 'ar' ? "order_2" : ""}`}>
                  <label className="labeltxt"> {t('advertisement_name')} </label>
                  {this.state.edit === true ?
                    <input
                      type="text"
                      className="html__input"
                      value={this.state.advertisement_name}
                    ></input> :
                    <input
                      type="text"
                      className="html__input"
                      value={this.state.advertisement_name}
                      onChange={(e) => {
                        this.setState({
                          advertisement_name: e.target.value,
                          advertisement_name_Error: "",
                        });
                      }}
                      onKeyDown={(e) => {
                        this.changeAdName(e);
                      }}
                    ></input>
                  }

                  <div className="validation__error" style={{ margin: "0px" }}>
                    {this.state.advertisement_name_Error &&
                      this.state.advertisement_name_Error}
                  </div>
                </div>
                <div style={{ width: "31.5%" }} className={`${this.props.i18n.language == 'ar' ? "order_1" : ""}`}>
                  <label className="labeltxt"> {t('advertisement_size')} </label>
                  <div
                    className={`${this.props.i18n.language == 'ar' ? "arabichalf_full_container" : "half_full_container"}`}
                    style={{ direction: this.props.i18n.language == 'ar' ? "rtl" : "", justifyContent: this.props.i18n.language == 'ar' ? "right" : "" }}
                  >
                    {this.state.sizeData.length > 0 &&
                      this.state.sizeData.map((checkingsize) => (
                        <div className="ad__size">
                          {this.state.edit === true ?
                            <Checkbox
                              checked={
                                this.state.adsize == checkingsize.id ? true : false
                              }
                              value={
                                this.state.edit ? this.state.adsize : checkingsize.ad_size
                              }
                            ></Checkbox>
                            :
                            <Checkbox
                              checked={this.state.adsize == checkingsize.id ? true : false}
                              value={this.state.edit ? this.state.adsize : checkingsize.ad_size}
                              onChange={() => this.storeadSize(checkingsize.id)}
                            ></Checkbox>
                          }
                          {checkingsize.size}
                        </div>
                      ))}
                  </div>

                  <div
                    className="validation__error--size"
                    style={{ margin: "0px" }}
                  >
                    {this.state.sizeError && this.state.sizeError}
                  </div>
                </div>
                <div style={{ width: "31.5%" }} className={`${this.props.i18n.language == 'ar' ? "order_0" : ""}`}>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                className="create_container"
                style={{ display: "flex", paddingBottom: '15px' }}
              >
                <div style={{ width: "37%", paddingRight: (this.props.i18n.language == 'ar' ? '0' : '3%'), paddingLeft: (this.props.i18n.language == 'ar' ? '3%' : '0') }} className={`${this.props.i18n.language == 'ar' ? "order_2" : ""}`}>
                  <div className="advertise_cost" style={{}}>
                    <label className="labeltxt" style={{ fontWeight: "normal" }}>
                      {t('fee_per_day')}<span style={{ fontSize: '10px' }}> {t('kwD')}</span>
                    </label>
                    <input
                      type="number"
                      className="html__input"
                      value={formatMoney(this.state.adfeeperday)}
                      disabled={true}
                    ></input>
                  </div>

                  <div className="validation__error" style={{ margin: "0px" }}>
                    {this.state.adfeeperday === "" &&
                      this.state.feeError &&
                      this.state.feeError}
                  </div>
                </div>

                <div style={{ width: "31.5%" }} className={`${this.props.i18n.language == 'ar' ? "order_1" : ""}`}>
                  <div className="advertise_cost" style={{}}>
                    <label className="labeltxt" style={{ fontWeight: "normal" }}>
                      {t('total_days')}
                    </label>
                    <input
                      type="number"
                      className="html__input"
                      value={this.state.noOfAvailableDays}
                      disabled={true}
                    />
                  </div>
                </div>

                <div style={{ width: "31.5%", paddingLeft: (this.props.i18n.language == 'ar' ? '0' : '3%'), paddingRight: (this.props.i18n.language == 'ar' ? '3%' : '0') }} className={`${this.props.i18n.language == 'ar' ? "order_0" : ""}`}>
                  <div
                    className="advertise_cost--unique"
                    style={{}}
                  >
                    <label className="labeltxt" style={{ fontWeight: "normal" }}>
                      {t('total_fee_with_kwd')}<span style={{ fontSize: '10px' }}>   {t('kwD')} </span>
                    </label>
                    <input
                      type="number"
                      className="html__input"
                      value={parseFloat(this.state.adtotalcost).toFixed(3)}
                      disabled={true}
                    ></input>
                  </div>
                  <div className="validation__error" style={{ margin: "0px" }}>
                    {this.state.adtotalcost === "" &&
                      this.state.totalcostError &&
                      this.state.totalcostError}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={12} className="create_container">
                <div className={`${this.state.hidefilelist && "advertise_uploadfile"} advertise_upload`}>
                  <label style={{ fontWeight: "normal" }} className="labeltxt">
                    {t('upload_advertisement')}
                  </label>
                  <span style={{ float: "right", marginTop: '5px' }} className={`${localStorage.nurse_language == 'ar' ? "ar_intro" : ""}`}>
                    <FiInfo onClick={this.handleOpen} className={`${localStorage.nurse_language == 'ar' ? "ar_info_icon" : ""} info_icon`} />{" "}
                    <span style={{ color: "let(--app-theme)", fontSize: "12px" }}>
                      {" "}
                      <a onClick={() => this.handleOpenVideoPlayerPopup(true)}>
                        {t('how_to_upload_advertisement')}{" "}
                      </a>
                    </span>{" "}
                  </span>
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                  <div className="upload__container_advertise">
                    <input
                      type="text"
                      value={this.state.filename}
                      className="html__input-box"
                      placeholder="My image.jpg"
                      onClick={() => this.validation() === true ? document.getElementById("getFile").click() : ""}
                    />
                    <input
                      type="file"
                      id="getFile"
                      onChange={this.uploadFile}
                      className="hideFile"
                    />
                  </div>

                  <Button
                    className="button_browse upload__pics--btn"
                    onClick={() => this.validation() === true ? document.getElementById("getFile").click() : ""}
                  >
                    {t('browse')}
                  </Button>
                </div>

                {this.state.setlet_Progressing > 0 &&
                  localStorage.nurse_language == 'ar' ?
                  <div className="progress_container_adv">
                    <span>{`${this.state.setlet_Progressing}%`}</span>
                    <progress max="100" className={`${localStorage.nurse_language == 'ar' ? "arabProgress" : ""}`} value={this.state.setlet_Progressing}></progress>
                  </div>
                  :
                  this.state.setlet_Progressing > 0 &&
                    localStorage.nurse_language != 'ar' ?
                    <div className="progress_container_adv">
                      <progress max="100" className={`${localStorage.nurse_language == 'ar' ? "arabProgress" : ""}`} value={this.state.setlet_Progressing}></progress>
                      <span>{`${this.state.setlet_Progressing}%`}</span>
                    </div>
                    :
                    <div>
                    </div>
                }

                <div className="validation__error" style={{ margin: "0px" }}>
                  {this.state.imageError && this.state.imageError}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modalcomp
          xswidth={"xs"}
          clrgreen
          title={t('upload_instruction')}
          visible={this.state.open}
          closemodal={this.handleClose}
          modelwidthClass="upload_ins_popup"
          t={t}
          i18n={this.props.i18n}
          clrchange="text_color"
        >
          <UploadMedia t={t}
            size={this.state.upload_file_size.size_in_mb}
            i18n={this.props.i18n} />
        </Modalcomp>


        <div className="Upload-modal-container">
          <Modalcomp
            visible={this.state.videoPlayerPopupOpen}
            closemodal={() => this.handleOpenVideoPlayerPopup(false)}
            title={t('example_upload_video')}
            clrchange="text_color"
            mdwidth={"md"}
            t={t}
            i18n={this.props.i18n}
          >
            <VideoPlayerPopup
              visible={this.state.videoPlayerPopupOpen}
              closemodal={() => this.handleOpenVideoPlayerPopup(false)}
              t={t}
              i18n={this.props.i18n}
            />
          </Modalcomp>
        </div>


        <Dialog
          className="Dialogmodal ticket_table_success"
          open={this.state.paymentTypeModel}
          aria-labelledby="customized-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          disableBackdropClick={true}
        >
          <div>
            <div className="payment_type_header">{t("selectaPaymentType")}</div>
            <div className="payment_type_body" style={{ direction: localStorage.nurse_language == 'ar' ? "rtl" : "" }}>
              <img
                onClick={(e) => this.selectPaymentType("mastercard")}
                className="payment_img_mastercard"
                src={mastercard}
                alt="mastercard"
              />
              <img
                onClick={(e) => this.selectPaymentType("knet")}
                className="payment_img_knet"
                src={knetImage}
                alt="knetImage"
              />
            </div>

            <div
              className="contract_approve_btn_div"
              style={{ marginBottom: "3rem" }}
            >
              <Button
                className="ticketSubmit"
                style={{ marginTop: "20px" }}
                onClick={() => {
                  this.handleClosePaymentTypeModel();
                }}
              >
                {" "}
                {t("cancel")}{" "}
              </Button>
            </div>
          </div>
        </Dialog>


        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={() => { this.handleCloseSuccess(); this.viewProfile() }}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
      </div>
    );
  }
};

AdBooking.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  closemodal: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editopenModal: PropTypes.func,
  editData: PropTypes.shape({
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
  }),
};
AdBooking.defaultProps = {
  editData: null,
};

export default withRouter(AdBooking);