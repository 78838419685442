import React, { Component } from "react";
import { Upload, message, Spin } from "antd";
import "antd/dist/antd.css";
import "./Profilepage.css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { IoIosGlobe } from "react-icons/io";
import EditIcon from "@material-ui/icons/Edit";
import BasicDetails from "./BasicDetails";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { MdLocationOn, MdLocalPhone, MdEmail } from "react-icons/md";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import Axios from "axios";
import { apiurl } from "../../App";
import ShowNotification from "../ShowNotification/ShowNotification";
import { withTranslation } from 'react-i18next';
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CryptoJS from "crypto-js";
import PropTypes from "prop-types";
import BankCommission from "./BankCommission";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}


class ProfileComp extends Component {
  constructor(props,) {
    super(props);
    this.state = {
      date: "rrr",
      open: false,
      Image: "",
      imagedata: [],
      imageChanged: false,
      ProfileData: [],
      props_loading: true,
      visible: false,
      successmsg: "",
      issuccess: false,
      imageurlS3: [],
      imageUrl: "",
      miniDrawser: false,
      vendorTypeID: "",
      commission_details: false,
      basic_details: true
    };
  }

  handleopen = (id, profilepath) => {
    this.setState({
      editid: id,
      open: true,
      imageUrl: profilepath,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      imageurlS3: [],
      imageUrl: "",
    });
  };


  ProfileGetApi = () => {
    let self = this;
    Axios({
      method: "POST",
      url: apiurl + "Nurse/getnursevendorprofile",
      data: {
        nursevendorId: localStorage.nurse_vendor_id
          ? localStorage.nurse_vendor_id
          : 0,
      },
    })
      .then((response) => {
        let ProfileData = [];
        ProfileData = response.data.data;
        let soeme = this.props.ProfileGetApi();
        this.setState({
          ProfileData,
          imageUrl: response.data.data[0].vendor_profile_path,
          vendorTypeID: response.data.data[0].VendorId.toString(),
          props_loading: false,
          miniDrawser: true
        });
      })
      .catch((error) => { });
  };

  componentDidMount() {
    this.ProfileGetApi();
  }

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append('module_id', "5");
      formData.append('file', info.file.originFileObj);
      formData.append('pathLocation', "PROFILE/")

      Axios({
        method: "POST",
        url: apiurl + "admin/awsS3FileUpload",
        data: formData,
      }).then((response) => {
        this.setState({
          imageurlS3: response.data.filepath.Location,
          imageUrl: response.data.filepath.Location
        }
        );
      });
    }
  };

  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  };

  async getEncryptedData(id, vendorTypeID) {
    let requestdata = {
      id: id,
      vendor_type: vendorTypeID,
    };

    try {
      const response = await Axios.post(apiurl + "admin/decryptedContent_data", requestdata);
      return response;
    } catch (error) {
      throw error;
    }
  }

  Decrypted_Editor_content = (encryptedContent) => {
    const fkey = CryptoJS.enc.Hex.parse(
      "00112233445566778899aabbccddeeff00112233445566778899aabbccddeeff"
    );
    try {
      const fiv = CryptoJS.enc.Hex.parse("00112233445566778899aabbccddeeff");
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedContent, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedContent = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedContent;
    } catch (error) {
      return error;
    }
  };

  formatDate1 = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day}-${month}-${year}`;
  };

  pdfgenerate = async () => {
    try {
      if (
        (this.state.vendorID == "" || this.state.vendorID == null || this.state.vendorID == undefined) &&
        (this.state.vendorTypeID == "" ||
          this.state.vendorTypeID == null ||
          this.state.vendorTypeID == undefined)
      ) {
        return;
      }
      const getEncypted = await this.getEncryptedData(this.state.vendorTypeID, "Nurse");
      if (Object.keys(getEncypted?.data.data).length == 0) {
        return;
      }
      const decryptedContent =
        getEncypted.data.data.contract_pdf_eData;
      const content = htmlToPdfmake(this.Decrypted_Editor_content(decryptedContent));
      if (!content) {
        throw new Error("HTML content is null or undefined");
      }
      const documentDefinition = {
        content: [
          ...content,
        ],
        styles: {
          header: {
            fontSize: 12,
            bold: true,
            margin: [40, 10, 40, 10],
          },
          footer: {
            fontSize: 10,
            margin: [40, 0, 40, 0],
          },
          main: {
            margin: [40, 20, 40, 20],
          },
        },
        header: function (currentPage) {
          if (currentPage != 1) {
            const modified_on = new Date(getEncypted?.data.data.modified_on)
            return {
              text: `Published Date :`,
              alignment: "left",
              style: "header",
              color: "#510F30",
              margin: [30, 40, 0, 0],
            };
          } else {
            return {
              text: '',
              margin: [30, 10, 0, 0],
            }
          }
        },

        footer: (currentPage, pageCount) => {

          const vendorName = this.state.ProfileData[0]?.vendor_name;
          const modified_on = getEncypted?.data.data.modified_on
            ? new Date(getEncypted?.data.data.modified_on)
            : new Date();
          return {
            margin: [40, 40, 40, 40],
            columns: [
              {
                stack: [
                  {
                    text: `Service Provider Signature`,
                    alignment: "left",
                    style: "footer",
                    color: "#510F30",
                  },
                  {
                    table: {
                      body: [
                        [
                          {
                            image: getEncypted?.data.data.profile_imageBlob,
                            width: 50,
                            height: 50,
                            margin: [0, 10, 10, 0],
                          },
                          {
                            text: vendorName,
                            alignment: 'left',
                            color: '#510F30',

                            margin: [0, 20, 10, 0],
                          },
                          [
                            {
                              image: getEncypted?.data.data.convertblob,
                              width: 60,
                              height: 60,
                              alignment: 'left',

                            },
                            {
                              text: this.formatDate1(modified_on),
                              alignment: 'left',
                              color: '#510F30',
                            }
                          ]
                        ]
                      ]
                    },
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function (i, node) {
                        return 4;
                      },
                      paddingRight: function (i, node) {
                        return 4;
                      },
                      paddingTop: function (i, node) {
                        return 4;
                      },
                      paddingBottom: function (i, node) {
                        return 4;
                      },
                    }
                  },
                  {
                    text: `Page ${currentPage}/${pageCount}`,
                    alignment: "right",
                    color: "#510F30",
                    style: "footerText",
                  },
                ],
              },
            ],
          };
        },
        styles: {
          footerText: {
            fontSize: 10,
          },
          footer: {
            fontSize: 10,
            bold: true,
          },
        },
        pageMargins: [40, 70, 40, 180],
      };
      pdfMake.createPdf(documentDefinition).open();
    } catch (error) {
      console.log(error);
    }
  };

  BasicDetailsfn = () => {
    this.setState({ commission_details: false, basic_details: true })
  }

  CommissionDetailsfn = () => {
    this.setState({ commission_details: true, basic_details: false })
  }


  render() {
    const uploadButton = (
      <div>
        <div className="upload-icon">
          <i class="fa fa-user-plus"></i>
        </div>
      </div>
    );
    const { imageUrl } = this.state;
    const { classes, onClose, selectedValue, t, ...other } = this.props;

    return (
      <div className={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} deal_listcreatead`}>
        <Paper className="profile_background">
          <div className="profileback_first">{t('profile_head')}</div>
          <div className="profilepaper_container">
            <Paper
              className={
                this.state.props_loading === true
                  ? "profile_backchange"
                  : "profilebackground"
              }
            >
              <Spin
                className="profile_spinner_align"
                spinning={this.state.props_loading}
              >
                <div className="total">
                  {this.state.ProfileData.map((val) => {
                    return (
                      <Grid container>
                        <Grid item xs={12} md={5} className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} `}>
                          <div className="profile_imageuser_container">
                            <div className="profile_imageuser_div">
                              <img
                                className="profile_imageuser"
                                src={val.vendor_profile_path}
                              />
                            </div>
                          </div>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={7}
                          className="addprofile_gridcontainer"
                        >
                          <div className="profile_nursecontainer">
                            <div style={{ display: 'flex' }}>
                              <div className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} `} style={{ width: '95%' }}>
                                <h1 className="vendor_name" style={{ padding: '0px' }}>
                                  {val.vendor_name}
                                </h1>
                              </div>
                              <div className={`${this.props.i18n.language == 'ar' ? "order_0" : ""} profile_icon_edit `} >
                                <EditIcon
                                  className="icon"
                                  onClick={() => this.handleopen(val.VendorId, val.vendor_profile_path)}
                                  onClose={this.props.handleClose}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="age_details">
                                <div
                                  style={{ order: (this.props.i18n.language == 'ar' ? '0' : '') }}
                                >
                                  <MdLocationOn className="icon_groups" />
                                </div>
                                <p className="profile_text">
                                  {val.vendor_address}
                                </p>
                              </div>
                              <div className="age_details">
                                <div style={{ order: (this.props.i18n.language == 'ar' ? '0' : '') }}>
                                  <MdLocalPhone className="icon_groups" />
                                </div>
                                <p className="profile_text">
                                  {val.vendor_phone}
                                </p>
                              </div>
                              <div className="age_details">
                                <div style={{ order: (this.props.i18n.language == 'ar' ? '0' : '') }}>
                                  <MdEmail className="icon_groups" />
                                </div>
                                <p className="profile_text">
                                  {val.vendor_email}
                                </p>
                              </div>
                              <div className="age_details">
                                <div>
                                  {val.vendor_website == "" || val.vendor_website == null
                                    || val.vendor_website == "null" ? "" : <IoIosGlobe className="icon_groups" />}
                                </div>
                                <p className="profile_text">
                                  {val.vendor_website == "" || val.vendor_website == null
                                    || val.vendor_website == "null" ? "" : val.vendor_website}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            className={`${this.props.i18n.language == "ar" ? "termsConditionsHeaderAR" : "termsConditionsHeader"}`}
                          >
                            <p className="termsConditions" onClick={() => { this.pdfgenerate(); }}>
                              {t("Service_Provider_Terms_Conditions")}
                            </p>
                          </div>

                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </Spin>
            </Paper>
          </div>
        </Paper>

        <div className="prof_edit">
          <Modalcomp
            visible={this.state.open}
            closemodal={this.handleClose}
            modelwidthClass={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} `}
            title={
              <div className="pro_headers" style={{ direction: this.props.i18n.language == 'ar' ? "rtl" : "" }}>
                <div className="profile_container" style={{ marginLeft: this.props.i18n.language == 'ar' ? "30px" : "" }}>
                  <div div className="profile_imagediv">
                    <div className="User-upload-container">
                      <Upload
                        name="avatar"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiurl + "admin/uploadProfileImage"}
                        beforeUpload={beforeUpload}
                        onChange={(e) => this.handleChange(e)}
                      >
                        <div className={`${localStorage.nurse_language == 'ar' ? "ar_camera_icon" : ""} pic_align`}>
                          <AddAPhotoIcon className="add_icon_profile" />
                        </div>
                        {imageUrl && (
                          <img
                            src={imageUrl}
                            className="upload-img-circle"
                            alt="avatar"
                          />
                        )}
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className={this.state.basic_details ? "basic_head_border" : "basic_head"} onClick={this.BasicDetailsfn}>
                  {t("basic_details")}
                </div>
                <div className={this.state.commission_details ? "basic_head_border" : "basic_head"} onClick={this.CommissionDetailsfn}>
                  {t("bankCommission")}
                </div>

                {" "}
              </div>
            }
            t={t}
            i18n={this.props.i18n}
          >
            {this.state.basic_details ? (
              <BasicDetails
                Image={this.state.imageurlS3}
                EditData={this.state.ProfileData}
                EditOpen={this.state.open}
                closemodal={this.handleClose}
                EditId={this.state.editid}
                ProfileGetApi={() => this.ProfileGetApi()}
                imageChanged={this.state.imageChanged}
                handleopenSuccess={this.handleopenSuccess}
                t={t}
                i18n={this.props.i18n}
              />
            ) : (
              <BankCommission
                Image={this.state.imageurlS3}
                EditData={this.state.ProfileData}
                EditOpen={this.state.open}
                closemodal={this.handleClose}
                EditId={this.state.editid}
                ProfileGetApi={() => this.ProfileGetApi()}
                imageChanged={this.state.imageChanged}
                handleopenSuccess={this.handleopenSuccess}
                t={t}
                i18n={this.props.i18n}
              />
            )
            }

          </Modalcomp>

          <ShowNotification
            visible={this.state.visible}
            successmsg={this.state.successmsg}
            issuccess={this.state.issuccess}
            handleCloseSuccess={this.handleCloseSuccess}
            t={t}
            i18n={this.props.i18n}
          ></ShowNotification>
        </div>
      </div>
    );
  }
};

ProfileComp.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  closemodal: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editData: PropTypes.shape({
    id: PropTypes.string,
  }),
};
ProfileComp.defaultProps = {
  editData: null,
};

export default withTranslation()(ProfileComp);