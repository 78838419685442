import React from "react";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import More from "../../Images/more.svg";
import "./TicketMaster.css";
import PropTypes from "prop-types";

let moment = require("moment");
class TicketView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket_no: "",
      status: "",
      menu: "",
      remark: "",
      ticket_date: "",
      ticket_time: "",
      response: [],
      playRecord: false,
      audio_url: "",
      imagedata: [],
      imagepathname: "",
      audio_record: '',
      audiopathname: "",
    };
  }

  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.toLocaleDateString("en-US", { day: "2-digit" });
    const month = date.toLocaleDateString("en-US", { month: "short" });
    const year = date.toLocaleDateString("en-US", { year: "2-digit" });
    return `${day}-${month}-${year}`;
  };

  convertTo12HourFormat = (time24) => {
    if (time24) {
      const [hour, minute] = time24.split(":");
      let period = "am";
      let hour12 = parseInt(hour);
      if (hour12 >= 12) {
        if (hour12 > 12) {
          hour12 -= 12;
        }
        period = "pm";
      }
      const hour12Formatted = hour12.toString().padStart(2, "0");
      return `${hour12Formatted}:${minute} ${period}`;
    } else {
      return "N/A";
    }
  }

  componentDidMount() {
    debugger
    if (this.props.editdetails) {
      let response = this.props.editdetails.comments_arr
        ? JSON.parse(this.props.editdetails.comments_arr).sort((a, b) => {
          return a > b;
        })
        : [];
      this.setState({
        ticket_no: this.props.editdetails.ticket_no,
        status: this.getStatusByCode(this.props.editdetails.status),
        ticket_date: this.formatDate(this.props.editdetails.ticket_date),
        menu: this.props.editdetails.screen_name,
        remark: this.props.editdetails.remarks,
        response: this.props.editdetails.admin_comments,
        imagedata: this.props.editdetails.media_filepath,
        audio_record: this.props.editdetails.audio_path,
        imagepathname: this.props.editdetails.media_filepath.split('?')[0].split('/')[5],
        audiopathname: this.props.editdetails.audio_path.split('?')[0].split('/')[5],
        ticket_time: this.convertTo12HourFormat(this.props.editdetails.booked_time)
      });
    }
  }

  getStatusByCode = (data) => {
    switch (data) {
      case "P":
        return "Pending";
        break;
      case "RO":
        return "Reopened";
        break;
      case "C":
        return "Closed";
        break;
      case "RE":
        return "Resolved";
        break;
      default:
        return "";
        break;
    }
  };

  handleClickPlayOpen = (data, type) => {
    if (type == "audio") {
      this.setState({ playRecord: true, img_url: "", audio_url: data });
    } else {
      this.setState({ playRecord: true, audio_url: "", img_url: data });
    }
  };

  handleClickPlayclose = () => {
    this.setState({ playRecord: false, audio_url: "", img_url: "" });
  };

  render() {
    const { t } = this.props;
    return (
      <div className={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} ticket_view_div`}>
        <Grid container spacing={2}
          style={{
            padding: "0px 5rem 0px 5rem",
            direction:
              this.props.i18n.language == "ar"
                ? "rtl"
                : "",
          }}>
          <Grid item md={4} sm={4}>
            <Labelbox
                    style={{ textTransform: "none" }}
              className="label-box"
              labelname={t("tbl_head_ticket_id")}
              type="text"
              placeholder="Ticket ID"
              value={this.state.ticket_no}
              readOnly={true}
            />
          </Grid>

          <Grid item md={4} sm={4}>
            <Labelbox
              className="label-box"
              labelname={t("tbl_head_screen_name")}
              type="text"
              placeholder="Screen Name"
              value={this.state.menu}
              readOnly={true}
            />
          </Grid>
          <Grid item md={4} sm={4}>
            <Labelbox
              className="label-box"
              labelname={t("tbl_head_status")}
              type="text"
              placeholder="Status"
              value={this.state.status}
              readOnly={true}
            />
          </Grid>
          <Grid item md={4} sm={4}>
            <Labelbox
              className="label-box"
              labelname={t("tbl_head_ticket_date")}
              type="text"
              placeholder="Date"
              value={this.state.ticket_date}
              readOnly={true}
            />
          </Grid>
          <Grid item md={4} sm={4}>
            <Labelbox
              className="label-box"
              labelname={t("tbl_head_time")}
              type="text"
              placeholder="Time"
              value={this.state.ticket_time}
              readOnly={true}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <Labelbox
              className="label-box"
              labelname={t("tbl_head_issue_raised_by_vendor")}
              placeholder="Remarks"
              type="textarea"
              value={this.state.remark}
              readOnly={true}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <div className={`${this.props.i18n.language == "ar" ? "ar_lbltxt" : "lbltxt"
              } `}> {t("audio_recording")} </div>
            <div>
              {this.state.audio_record != "" && (
                <div
                  style={{
                    display: "flex",
                    fontSize: "14px",
                    margin: "10px 0px",
                  }}
                >
                  <div
                    style={{
                      whitespace: "nowrap",
                      width: "100%",
                      overflow: "hidden",
                      textoverflow: "ellipsis",
                    }}
                  >
                    {this.state.audiopathname}
                  </div>
                  <div style={{ width: "20%", textAlign: "center" }}>
                    <PlayArrowIcon
                      style={{
                        fill: "#510f30",
                        fontSize: "2rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.handleClickPlayOpen(
                          this.state.audio_record,
                          "audio"
                        );
                      }}
                    ></PlayArrowIcon>
                  </div>
                </div>
              )}
            </div>
          </Grid>

          <Grid item md={6} sm={6}>
            <div className={`${this.props.i18n.language == "ar" ? "ar_lbltxt" : "lbltxt"
              } `}> {t("screenshot_or_picture")}</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {this.state.imagedata != "" && (
                <div
                  style={{
                    display: "flex",
                    fontSize: "14px",
                    margin: "10px 0px",
                  }}
                >
                  <div
                    style={{
                      whitespace: "nowrap",
                      width: "210px",
                      overflow: "hidden",
                      textoverflow: "ellipsis",
                      wordBreak: 'break-all',
                    }}
                  >
                    {this.state.imagepathname}
                  </div>
                  <div>
                    {" "}
                    <a href={this.state.imagedata} target="_blank">
                      <img
                        src={More}
                        style={{
                          fill: "#5377ee",
                          fontSize: "2rem",
                          cursor: "pointer",
                          marginTop: "-3px"
                        }}
                      ></img>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={12} sm={12}>
            <div className={`${this.props.i18n.language == "ar" ? "ar_lbltxt" : "lbltxt"
              } `} style={{ marginBottom: "7px" }}>
              {" "}
              {t("tbl_head_Issue_addressed_by_tom")}{" "}
            </div>
            <div className={`${localStorage.nurse_language == 'ar' ? "ar_align" : ""} response_main_div`} >
              {this.state.response}
            </div>
          </Grid>
        </Grid>

        <Modalcomp
          visible={this.state.playRecord}
          xswidth="xs"
          title={this.state.audio_url != "" ? localStorage.nurse_language == 'ar' ? "تشغيل التسجيل" : "PLAY RECORDING" : localStorage.nurse_language == 'ar' ? "عرض الصورة" : "VIEW IMAGE"}
          clrchange="text_clr_change"
          closemodal={(e) => this.handleClickPlayclose(e)}
          t={t}
          i18n={this.props.i18n}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              {this.state.audio_url != "" && (
                <audio
                  controls
                  src={this.state.audio_url}
                  type="audio/mpeg"
                ></audio>
              )}
            </div>
            <div style={{ textAlign: "center" }}>
              {this.state.audio_url == "" && (
                <img
                  id="preimg"
                  src={this.state.img_url}
                  style={{ height: "300px", width: "auto" }}
                />
              )}
            </div>
          </div>
        </Modalcomp>
      </div>
    );
  }
};

TicketView.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  closemodal: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editData: PropTypes.shape({
    id: PropTypes.string,
    media_filepath: PropTypes.string,
  }),
};
TicketView.defaultProps = {
  editData: null,
};
export default TicketView;
