import React, { Component } from "react";
import Axios from "axios";
import { apiurl } from "../../App";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Signature from "./Signature";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import "./Login.css";
import CloseIcon from "@material-ui/icons/Close";
import DeleteMedia from "../../helpers/ModalComp/deleteModal";
import ShowNotification from "../ShowNotification/ShowNotification";
import axios from "axios";
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CryptoJS from "crypto-js";
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default class ApprovalPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commissioner_name: "",
      commissioner_name_err: false,
      profile_image: "",
      signature_image: "",
      openSignature: false,
      error_msg: "",
      imageData: [],
      imageUrl: null,
      profilename: "",
      profilepath: "",
      approvalpopupClosevalue: "",
      deleteopen: false,
      RegisterationPushNotification: false,
      vendor_name: "",
      view_condition_pdf: true,
      accept_mail_generate: false,
    };
    this.getContractDetails();
  }

  componentDidMount() {
    this.vendorprofile();
  }



  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  signatureSave(data) {
    this.setState({
      openSignature: false,
      signature_image: data,
      error_msg: "",
    });
  }

  handleClose = () => {
    this.setState({ openSignature: false });
  };

  handlemodalClose = () => {
    this.props.approvalpopupClose();
    localStorage.setItem("approvalpopupClose", "close");
  };

  checkValidation() {
    var is_return = true;
    if (this.state.commissioner_name == "") {
      this.setState({ commissioner_name_err: true })
      is_return = false;
    }

    if (this.state.signature_image == "") {
      this.setState({ error_msg: "Please add signature" });
      is_return = false;
    }

    // if (this.props.contractDetails.data.data[0].signed_contract_file == null) {
    //   this.handleopenSuccess("Please contact admin.", false)

    //   is_return = false;
    // }
    // if (this.props.contractDetails.data.data[0].terms_and_condition_file == null) {
    //   this.handleopenSuccess("Please contact admin.", false)

    //   is_return = false;
    // }

    return is_return;
  }




  saveContract() {
    if (this.checkValidation()) {
      this.setState({ view_condition_pdf: false });
      this.setState({ accept_mail_generate: true });
      this.TermsConditions();
      var file = this.dataURLtoFile(this.state.signature_image, "signature.png");
      const formData = new FormData();
      formData.append('file', file);
      formData.append('module_id', "5");
      formData.append('pathLocation', "SIGNATURE/")
      Axios({
        method: "POST",
        url: apiurl + "admin/awsS3FileUpload",
        data: formData,
      })
        .then(async (response) => {
          debugger
          this.setState({ setVar_File: response.data.filepath.privatePathUrl })
          if (this.state.setVar_File != "") {
            await this.Signaturecontractsave(this.state.setVar_File);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }

  Signaturecontractsave = async (data) => {
    debugger
    let requestdata = {
      "contract_id": this.props.contract_id,
      "module_name": "Nurse",
      "signature_image": data,
      "vendor_id": localStorage.getItem('vendorId'),
      "token": localStorage.getItem('tokenHeader'),
    };

    await Axios({
      method: "POST",
      url: apiurl + "admin/vendorContractApproval",
      data: requestdata,
    })
      .then((response) => {
        debugger;
        if (response.data.data[0]['ErrorCode']) {
          this.props.approvalpopupClose()
          localStorage.setItem('approvalpopupClose', "accept");
          localStorage.setItem('VendorName', response.data.data[0].vendor_name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }



  getContractDetails() {
    var data = {
      vendor_id: localStorage.getItem("vendorId")
        ? localStorage.getItem("vendorId")
        : 0,
    };
    Axios({
      method: "POST",
      url: apiurl + "admin/getVendorContractWeb",
      data: data,
    })
      .then((response) => {
        this.sendToken(localStorage.getItem("vendorId"));
        this.changeLanguage(localStorage.getItem("vendorId"));
        this.setState({
          imageUrl: response.data.data[0].vendor_profile,
          commissioner_name: response.data.data[0].vendor_company_name //vendor_name,
        });

        localStorage.setItem("contarctId", response.data.data[0].id);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  rejectContract = () => {
    Axios({
      method: "POST",
      url: apiurl + "admin/rejectVendorContract",
      data: {
        contract_id: this.props.contract_id,
      },
    }).then((response) => {
      if (response.data.data[0]["ErrorCode"] == "9999") {
        this.handleopenSuccess(" Contract rejected successfully!", true);
      }
    });
  };

  removesig = () => {
    this.setState({ signature_image: "" });
  };

  vendorprofile = () => {
    Axios({
      method: "POST",
      url: apiurl + "admin/getvendorprofile",
      data: {
        vendor_id: this.props.vendor_id,
      },
    })
      .then((response) => {
        this.setState({
          profilepath: response.data.data[0].vendor_profile_path,
        });
        this.setState({ profilename: response.data.data[0].vendor_name });

      })

      .catch((error) => {
        console.log(error);
      });
  };

  TermsConditions = async () => {

    debugger
    const Decrypted_Editor_content = (encryptedContent) => {
      const fkey = CryptoJS.enc.Hex.parse(
        "00112233445566778899aabbccddeeff00112233445566778899aabbccddeeff"
      );
      try {
        const fiv = CryptoJS.enc.Hex.parse("00112233445566778899aabbccddeeff");
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedContent, fkey, {
          iv: fiv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const decryptedContent = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedContent;
      } catch (error) {
        return error;
      }
    };


    async function getEncryptedData(id, vendorTypeID) {
      let requestdata = {
        id: id,
        vendor_type: vendorTypeID,
      };

      try {
        const response = await axios.post(apiurl + "admin/decryptedContent_data", requestdata);
        console.log("Response:", response);
        return response;
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }

    }


    const formatDate = (date) => {
      const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with zero if needed
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()]; // Get month name from array
      const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

      return `${day}-${month}-${year}`; // Format the date
    };

    const getEncypted = await getEncryptedData(localStorage.getItem("vendorId"), "Nurse");

    if (Object.keys(getEncypted?.data.data).length == 0) {
      return;
    }
    const content = await htmlToPdfmake(Decrypted_Editor_content(getEncypted?.data.data.contract_pdf_eData));

    console.log("content", content);
    const documentDefinition = {
      content: [...content],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          margin: [40, 10, 40, 10],
        },
        footer: {
          fontSize: 10,

          margin: [40, 0, 40, 0],
        },
        main: {
          margin: [40, 20, 40, 20],
        },

      },
      header: function (currentPage, pageCount, pageSize) {

        if (currentPage != 1) {

          const modified_on =
            getEncypted != false && getEncypted?.data.data.modified_on
              ? new Date(getEncypted?.data.data.modified_on)
              : new Date();

          return {
            text: `Published Date :${formatDate(modified_on)}`,
            alignment: "left",
            style: "header",
            color: "#510F30",
            margin: [30, 40, 0, 0],
          };
        } else {
          return {
            text: '',
            margin: [30, 10, 0, 0],
          }

        }
      },

      footer: (currentPage, pageCount) => {

        const modified_on = getEncypted?.data.data.modified_on
          ? new Date(getEncypted?.data.data.modified_on)
          : new Date();
        if (this.state.accept_mail_generate) {

          return {
            margin: [40, 40, 40, 40],
            columns: [
              {
                stack: [
                  {
                    text: `Service Provider Signature`,
                    alignment: "left",
                    style: "footer",
                    color: "#510F30",
                  },
                  {
                    table: {
                      body: [
                        [
                          {
                            image: getEncypted?.data.data.profile_imageBlob,
                            width: 50,
                            height: 50,
                            margin: [0, 10, 10, 0],
                          },
                          {
                            text: localStorage.getItem("user_name"),
                            alignment: 'left',
                            color: '#510F30',

                            margin: [0, 20, 10, 0],
                          },
                          [
                            {
                              image: this.state.signature_image,
                              width: 60,
                              height: 60,
                              alignment: 'left',

                            },
                            {
                              text: formatDate(modified_on),
                              alignment: 'left',
                              color: '#510F30',


                            }
                          ]




                        ]
                      ]
                    },
                    layout: {
                      hLineWidth: function (i, node) {
                        return 0;
                      },
                      vLineWidth: function (i, node) {
                        return 0;
                      },
                      paddingLeft: function (i, node) {
                        return 4;
                      },
                      paddingRight: function (i, node) {
                        return 4;
                      },
                      paddingTop: function (i, node) {
                        return 4;
                      },
                      paddingBottom: function (i, node) {
                        return 4;
                      },

                    }
                  },
                  {
                    text: `Page ${currentPage}/${pageCount}`,
                    alignment: "right",
                    color: "#510F30",
                    style: "footerText",
                  },
                ],
              },
            ],
          };


        } else {
          return {
            margin: [40, 0, 40, 40],
            columns: [
              {
                stack: [
                  {
                    text: `Page ${currentPage}/${pageCount}`,
                    alignment: "right",
                    color: "#510F30",
                    margin: [0, 80, 10, 0],
                  },
                ],
              },
            ],
          };
        }
      },
      styles: {
        footerText: {
          fontSize: 10,

        },
        footer: {
          fontSize: 10,
          bold: true,
        },
      },
      pageMargins: [40, 70, 40, 180], // Page margins (left, top, right, bottom)
    };
    {
      this.state.view_condition_pdf &&
        pdfMake.createPdf(documentDefinition).open();
    }

    {
      this.state.accept_mail_generate &&

        await pdfMake.createPdf(documentDefinition).getBlob(async (blob) => {

          const formData = new FormData();

          formData.append('file', blob, 'document.pdf');
          formData.append('module_id', "5");
          formData.append('pathLocation', "PROFILE/")
          const uploadUrl = apiurl + "admin/awsS3FileUpload";
          try {
            const response = await axios.post(uploadUrl, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            await axios.post(apiurl + 'admin/decryptedContent_data_details', { "filepath": response.data.filepath.Location, "name": localStorage.getItem("user_name"), "email": [localStorage.getItem("Vemail"), "thanesh@avelator.com"], "vendorContranct": "vendorContranct" });
          } catch (error) {
            console.error('Error uploading file', error);
          }
        })
    }


  }


  SignedContract = () => {
    if (this.props.contractDetails.data.data[0].signed_contract_file != "") {
      window.open(
        this.props.contractDetails.data.data[0].signed_contract_file,
        "_blank"
      );
    }
  };

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(","),
      mime = arr?.[0]?.match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  closemodal = () => {
    /* ; */
    this.setState({
      openview: false,
      editopen: false,
      deleteopen: false,
      workflow: false,
    });
  };
  deleteopen = (type, id) => {
    this.setState({
      deleteopen: true,
      iddata: id,
    });
  };

  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  }

  handleCloseSuccess = () => {
    this.setState({ visible: false });
    this.props.approvalpopupClose();
    localStorage.setItem("approvalpopupClose", "close");

  }

  sendToken = (id) => {
    Axios({
      method: "POST",
      url: apiurl + "admin/insertvendorwebtoken",
      data: {
        "vendor_id": id,
        "token": localStorage.getItem("TokenFCM")
      },
    })
      .then((response) => {
        console.log("update token response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeLanguage = (id) => {

    Axios({
      method: "POST",
      url: apiurl + "admin/insertvendorweblanguage",
      data: {
        "vendor_id": id,
        "language": "en"
      },
    })
      .then((response) => {
        console.log("update token response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }


  render() {
    const { t } = this.props;
    return (
      <div className="modaldiv">
        <Dialog
          visible={this.state.open}
          className={`${this.props.i18n.language == "ar" ? "arabic_cls" : ""
            } Dialogmodal approval_check_popup approval_contract_modal `}


          open={true}
          aria-labelledby="customized-dialog-title"
          maxWidth={"xs"}
          fullWidth={true}
          style={{ borderRadius: "0px 0px 46px 46px" }}
        >
          <MuiDialogTitle disableTypography className="header_details">
            <Typography
              variant="h6"
              className="contatct_approve_head approval_details"
            >
              {t("contract_approval")}
            </Typography>
            <CloseIcon
              className={`closemodal_icon`}
              onClick={this.handlemodalClose}
            />
          </MuiDialogTitle>
          <div className="approve_popu_main_div">
            <div>
              <div class="approval_img_container">
                <div className="profile_details">
                  <div
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                  >
                    <img
                      src={this.state.imageUrl}
                      className="upload-img-circle1"
                      alt="avatar"
                    />
                  </div>
                  <div className="profile_name_details">
                    {this.state.commissioner_name}
                  </div>
                </div>
              </div>
              <div className="terms_and_policies">
                <div>
                  <div className="policies_details">Terms & Conditions</div>
                  <div
                    className="view_terms_policies"
                    onClick={() => {
                      this.TermsConditions();
                    }}
                  >
                    View
                  </div>
                </div>
                <div>
                  <div className="policies_details"> Signed Contract</div>
                  <div
                    className="view_terms_policies"
                    onClick={() => {
                      this.SignedContract();
                    }}
                  >
                    View{" "}
                  </div>
                </div>
              </div>

              <div className="terms_and_policies1">
                <div>
                  <div className="vendor_signature_Tom_signature">
                    Vendor Signature
                  </div>
                  {this.state.signature_image == "" && (
                    <div>
                      <label
                        className="contract_form_label"
                        style={{ marginTop: "15px" }}
                        onClick={() => {
                          this.setState({ openSignature: true });
                        }}
                      >
                        {" "}
                        <div className="signature_details">
                          {" "}
                          Sign here{" "}
                        </div>{" "}
                      </label>
                    </div>
                  )}
                  {this.state.signature_image != "" && (
                    <div className="sig_container">
                      {" "}
                      <img
                        className="sig_img"
                        src={this.state.signature_image}
                        style={{ width: "100px" }}
                      />
                      <div
                        className="remove_container"
                        onClick={this.removesig}
                      >
                        X
                      </div>{" "}
                    </div>
                  )}
                </div>

              </div>
              {this.state.error_msg != "" && (
                <div className="sign_err">{this.state.error_msg}</div>
              )}
              <div
                style={{
                  marginTop: "5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  className="reject_btn"
                  onClick={() => this.deleteopen()}
                >
                  {" "}
                  {t("reject_button")}
                </Button>
                <Button
                  className="accept_btn contract_accept_btn"
                  onClick={() => {
                    this.saveContract();
                  }}
                >
                  {" "}
                  {t("accept")}
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <Modalcomp
          xswidth={"xs"}
          clrchange="textclr"
          title="SIGNATURE"
          visible={this.state.openSignature}
          modelwidthClass="signature_popup"
          closemodal={this.handleClose}
          t={t}
          i18n={this.props.i18n}
        >
          <Signature
            {...this.state}
            signatureSave={this.signatureSave.bind(this)}
            closeSignature={() => {
              this.handleClose();
            }}
            t={t}
            i18n={this.props.i18n}
          >
            {" "}
          </Signature>
        </Modalcomp>

        <Modalcomp
          visible={this.state.deleteopen}
          title={t("reject contract")}
          closemodal={this.closemodal}
          xswidth={"xs"}
          clrchange="text_clrchange"
          modelwidthClass="media_delete_popup"
          t={t}
          i18n={this.props.i18n}
        >
          <DeleteMedia
            deleterow={this.rejectContract}
            closemodal={this.closemodal}
            msg={t('Are you sure, you want to reject this contract?')}
            t={t}
            i18n={this.props.i18n}
          />
        </Modalcomp>

        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t}
          i18n={this.props.i18n}></ShowNotification>

        {this.state.RegisterationPushNotification &&
          <div class="pushNotification_body">

            <div class="pushNotification_content">
              Congratulations, {this.state.vendor_name}! You're officially registered with TOM.
            </div>
          </div>
        }

      </div>
    );
  }
}